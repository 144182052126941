<template>
    <div>
      <!-- Modal -->
      <div
        class="modal fade"
        id="DuplicateQuizModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref="EditQuizRef"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content DuplicateQuizContent">
            <div class="container">
              <div>
                <div class="container py-5 h-100">
                  <div class="">
                    <div class="row">
                      <div
                        class="w-100 d-flex flex-column justify-content-between "
                      >
                        <p class="Title">You're about to duplicate a quiz! </p>
                        <p class="TitleSub text-center">
                             Please choose the elements you'd like to include in the copy:
                        </p>

                        <div class="my-3 px-3">

                          <div class="d-flex pb-2">
                            <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          disabled
                          class="custom-control-input"
                          id="DesignDuplicate"
                          v-model="DesignDuplicate"
                         
                        />
                        <label
                          class="custom-control-label"
                          for="DesignDuplicate"
                        > <span class="editorStyleTitle"><strong>Quiz Design</strong>: Duplicate the quiz design and layout.</span></label>
                      </div>
                          </div>
                          <div class="d-flex pb-2">
                            <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="ProductMatchDuplicate"
                          v-model="ProductMatchDuplicate"
                         
                        />
                        <label
                          class="custom-control-label"
                          for="ProductMatchDuplicate"
                        > <span class="editorStyleTitle"><strong>Product Match</strong>: Duplicate the matching criteria related to the products in the quiz.</span></label>
                      </div>
                          </div>
                          <!-- <div class="d-flex pb-2">
                            <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="JumpLogicDuplicate"
                          v-model="JumpLogicDuplicate"
                         
                        />
                        <label
                          class="custom-control-label"
                          for="JumpLogicDuplicate"
                        > <span class="editorStyleTitle"><strong>Jump Logic</strong>: Include the logic that determines how questions flow from one to another.</span></label>
                      </div>
                          </div> -->
                          <!-- <div class="d-flex pb-2">
                            <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="TranslationDuplicate"
                          v-model="TranslationDuplicate"
                         
                        />
                        <label
                          class="custom-control-label"
                          for="TranslationDuplicate"
                        > <span class="editorStyleTitle"><strong>Translation</strong>: Copy the translations for the quiz content in various languages.</span></label>
                      </div>
                          </div> -->
                          <div class="d-flex pb-2">
                            <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="ScoreQuizDuplicate"
                          v-model="ScoreQuizDuplicate"
                         
                        />
                        <label
                          class="custom-control-label"
                          for="ScoreQuizDuplicate"
                        > <span class="editorStyleTitle"><strong>Score Quiz</strong>: Include the scoring mechanism that evaluates the quiz responses.</span></label>
                      </div>
                          </div>
                          <!-- <div class="d-flex pb-2">
                            <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="QuizSettingDuplicate"
                          v-model="QuizSettingDuplicate"
                         
                        />
                        <label
                          class="custom-control-label"
                          for="QuizSettingDuplicate"
                        > <span class="editorStyleTitle"><strong>Quiz Setting</strong>: Duplicate all the configurations and settings related to the quiz appearance, timing, and behavior.</span></label>
                      </div>
                          </div> -->
                        </div>
                        <div
                          class="d-flex align-items-center w-100 justify-content-center"
                        >
                          <button
                            @click="DuplicateQuiz"
                            class="CreateBtn mr-5 outline-none text-white outline-none border-0 px-4 py-2"
                            style="background: #ea1601cc; border-radius: 6px"
                          >
                            <span v-if="!duplicateSpinner">Duplicate</span>
                            <div
                              v-else
                              class="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </button>
                          <button
                            @click="CloseModal"
                            class="outline-none text-white outline-none border-0 px-3 py-2 see-demo-btn"
                            style="background: #4d1b7eaa; border-radius: 6px"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Actual Comp Start -->
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import axios from "axios";
  import $ from 'jquery'
  export default {
    data() {
      return {
        duplicateSpinner: false,
        Quiz: {},
        ProductMatchDuplicate:false,
        DesignDuplicate:true,
        // JumpLogicDuplicate:false,
        // TranslationDuplicate:false,
        ScoreQuizDuplicate:false,
        QuizSettingDuplicate:false,
      };
    },
    computed: {
      ...mapGetters(["GetTokenFromMetaTag"]),
    },
    methods: {
      CloseModal() {
        $("#DuplicateQuizModal").modal("hide");
      },
      OpenModal(quiz) {
        this.Quiz = quiz;
        $("#DuplicateQuizModal").modal("show");
      },
      async DuplicateQuiz() {
        
             this.duplicateSpinner = true;
      try {
        let data = {
        
          quizId: this.Quiz.id,
          product_maping: this.ProductMatchDuplicate,
// logic: this.JumpLogicDuplicate,
// quiz_etting: this.QuizSettingDuplicate,
score_quiz: this.ScoreQuizDuplicate,
// translation:this.TranslationDuplicate,
// integration: this.,
        };
        const response = await axios.post(`/copyQuiz`, data);
        if (response.status == 200) {
            this.CloseModal();
          this.$emit("UpdateDashboard");
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.duplicateSpinner = false;
      }
      },
    },
  };
  </script>
  
  <style scoped>
  .DuplicateQuizContent {
    border-radius: 20px;
    text-align: left;
  }
  .Title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    /* line-height: 72px; */
    color: #4d4950;
  }
  .TitleSub {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #4d4950bb;
  }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #323232 !important;
  border: none;
}

.custom-checkbox
  .custom-control-input:checked:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
  border: none;
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
  border: none;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  opacity: 0.5;
}
  </style>
  